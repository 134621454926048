$blockGap:20px;
$classItemGap:25px;
$headerPadding: 18px;
$headerMoreLabel:24px;
$panelPaddingLR:30px;
$navbarHeight:80px;

/*colors*/

$darkGrey:#565a5e;
$lightGrey:#8a8d91;
$orange:#f19533;
$headingImg:"https://shiyibao-1251321942.file.myqcloud.com/shiyibao/assets/img/toBusiness/index/headingImg.png";
$classInfoIcon: "https://shiyibao-1251321942.file.myqcloud.com/shiyibao/assets/img/toBusiness/index/classInfoIcon.png";
$footerIcon: "https://shiyibao-1251321942.file.myqcloud.com/shiyibao/assets/img/toBusiness/index/footerIcon.png";
$firmProduct: "https://shiyibao-1251321942.file.myqcloud.com/shiyibao/assets/img/toBusiness/index/firmProduct.png";
$sidebarIcon: "https://shiyibao-1251321942.file.myqcloud.com/shiyibao/assets/img/toBusiness/index/sidebarIcon.png";
%imgBlock {
	img {
		display: block;
		width: 100%;
	}
}
%height100 {
	height: 100%;
}
.bgsetter {
	background-color: #edeff0;
	//	min-width: 768px;
	&>div.container {
		//margin-top: 100px;
		position: relative;
		margin-bottom: 5rem;
	}
}
.bg-white {
	//background: white !important;
}
.padding-0 {
	padding: 0;
}
.mobile-padding-0 {
	@media (max-width: 767px) {
		padding-left: 0;
		padding-right: 0;
	}
}
.container {
	//	min-width: 768px;
	@media (min-width: 768px) {
		& {
			width: 700px;
		}
	}
	@media (min-width: 992px) {
		& {
			width: 900px;
		}
	}
	@media (min-width: 1200px) {
		& {
			width: 1100px;
		}
	}
}
.navbar {
	height: $navbarHeight;
	background-color: #fff;
	z-index:1000;
	.container {
		@extend %height100;
	}
	.navbar-header {
		@extend %height100;
	}
	.navbar-brand {
		@extend %height100;
		padding-top: 0;
		padding-bottom: 0;
		height: $navbarHeight;
		line-height: $navbarHeight;
		overflow: hidden;
		img {
			display: inline-block;
			width: 100%;
		}
	}
}
#shiyibao-navbar-collapse {
	width: 100%;
	//	background: white;
	margin: 0;
	.navbar-right {
		@media screen and (max-width: 767px) {
			& {
				margin: 0 -15px;
				background: white;
			}
		}
	}
}
.navbar-nav>li>a {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	line-height: $navbarHeight;
	font-size: 14px;
}
ul.sidebar-nav {
	position: fixed;
	top: 40%;
	left: 50%;
	padding: 0;
	list-style: none;
	z-index: 1000;
	width: 46px;
	li {
		display: inline-block;
		margin-bottom: 3px;
	}
	@media (min-width: 768px) {
		& {
			margin-left: 375px;
		}
	}
	@media (min-width: 992px) {
		& {
			margin-left: 450px;
		}
	}
	@media (min-width: 1200px) {
		& {
			margin-left: 550px;
		}
	}
}
footer {
	&.ft-setting {
		color: $lightGrey;
		background-color: #394047;
		padding-bottom: 15rem;
		a {
			color: $lightGrey;
		}
		strong {
			display: inline-block;
			margin-top: 30px;
			margin-bottom: 18px;
			font-size: 15px;
		}
	}
	ul {
		padding: 0;
		list-style: none;
		li {
			line-height: 1.8;
			padding: 0;
			font-size: 14px;
		}
	}
	.firm-copyright {
		border-top: 1px solid $darkGrey;
		margin-top: 20px;
		padding-top: 20px;
		padding-bottom: 50px;
	}
	.footer-logo {
		display: inline-block;
		width: 120px;
	}
}
%spriteIcon {
	display: inline;
	float: left;
	margin: 3px 10px 0 0;
}
i.heading-img {
	@extend %spriteIcon;
	#commentClasses & {
		width: 22px;
		height: 26px;
		background: url($headingImg) 0 0;
	}
	#selftestClasses & {
		width: 28px;
		height: 21px;
		background: url($headingImg) -22px 0;
	}
	#schoolsInfo & {
		width: 25px;
		height: 21px;
		background: url($headingImg) -50px 0;
	}
}
i.class-info-icon {
	@extend %spriteIcon;
	&.test-count {
		width: 20px;
		height: 20px;
		background: url($classInfoIcon) -60px 0;
	}
	&.ver-count {
		width: 20px;
		height: 20px;
		background: url($classInfoIcon) -40px 0;
	}
	&.comment-count {
		width: 20px;
		height: 20px;
		background: url($classInfoIcon) 0 0;
	}
	&.mate-count {
		width: 20px;
		height: 20px;
		background: url($classInfoIcon) -20px 0;
	}
}
%productIcon {
	width: 116px;
	height: 58px;
	cursor: pointer;
}
i.firm-product {
	@extend %spriteIcon;
	@extend %productIcon;
	&.termbox {
		background: url($firmProduct) -116px 0;
		&:hover {
			background: url($firmProduct) -116px 0;
		}
	}
	&.onedict {
		background: url($firmProduct) -116px -58px;
		&:hover {
			background: url($firmProduct) -116px -58px;
		}
	}
	&.tms {
		background: url($firmProduct) -116px -116px;
		&:hover {
			background: url($firmProduct) -116px -116px;
		}
	}
	&.employ {
		background: url($firmProduct) -116px -174px;
		&:hover {
			background: url($firmProduct) -116px -174px;
		}
	}
}
%sideBarIcon {
	width: 46px;
	height: 46px;
	cursor: pointer;
}
.sidebar-nav i {
	@extend %spriteIcon;
	@extend %sideBarIcon;
	margin: 0;
	&.qq {
		background: url($sidebarIcon) 0 0;
		&:hover {
			background: url($sidebarIcon) -46px 0;
		}
	}
	&.call {
		//		@extend %sideBarIcon;
		background: url($sidebarIcon) 0 -46px;
		&:hover {
			background: url($sidebarIcon) -46px -46px;
		}
	}
	&.que {
		//		@extend %sideBarIcon;
		background: url($sidebarIcon) 0 -92px;
		&:hover {
			background: url($sidebarIcon) -46px -92px;
		}
	}
	&.top {
		//		@extend %sideBarIcon;
		background: url($sidebarIcon) 0 -138px;
		&:hover {
			background: url($sidebarIcon) -46px -138px;
		}
	}
}
.footer-logo-wrapper {
	display: inline-block;
	line-height: 44px;
}
i {
	.ft-setting & {
		@extend %spriteIcon;
	}
	&.footer-logo {
		float: right;
		width: 120px;
		height: 44px;
		margin-left: 10px;
		cursor: pointer;
		background: url($footerIcon) 0 0;
	}
	&.footer-weixin {
		width: 23px;
		height: 19px;
		background: url($footerIcon) 0 -44px;
	}
	&.footer-weibo {
		width: 20px;
		height: 17px;
		margin-right: 13px;
		background: url($footerIcon) -23px -44px;
	}
	&.footer-blog {
		width: 20px;
		/*16*/
		height: 17px;
		/*16*/
		//		background: url($footerIcon) -57px -44px;
		margin-right: 13px;
		background: url($footerIcon) -23px -44px;
	}
	&.footer-phone {
		width: 15px;
		height: 15px;
		background: url($footerIcon) -73px -44px;
	}
	&.footer-mail {
		width: 15px;
		height: 10px;
		margin-top: 8px;
		background: url($footerIcon) -88px -44px;
	}
	&.footer-qqgroup {
		width: 14px;
		height: 16px;
		background: url($footerIcon) -43px -44px;
	}
}
img {
	&.img-weixin-qr {
		position: absolute;
		bottom: 0;
		right: -80px;
		width: 100px;
		z-index: 100;
		box-shadow: 2px 2px 10px #292929;
		display: none;
		#weixinQR:hover & {
			display: block;
		}
	}
}
.text-oh {
	width: 90%;
	white-space: nowrap;
	text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	overflow: hidden;
}
